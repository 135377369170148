<template>
    <div class="form-switch" :data-selected="selected" :data-values="title_3 ? 3 : 2">
        <button ref="option1" @click.prevent="value = value_1">{{ title_1 }}</button>
        <button ref="option2" @click.prevent="value = value_2">{{ title_2 }}</button>
        <button ref="option3" v-if="title_3" @click.prevent="value = value_3">{{ title_3 }}</button>
        <span :style="{width: width, left: left}" class="form-switch__bg"></span>
    </div>
</template>

<script>
export default {
    name: 'FieldSwitch',
    props: {
        title_1: String,
        title_2: String,
        title_3: String,
        value_1: [String, Number, Boolean],
        value_2: [String, Number, Boolean],
        value_3: [String, Number, Boolean],
        model: [String, Number, Boolean]
    },
    data() {
        return {
            value: null,
            width: '0px',
            left: '0px'
        }
    },
    computed: {
        selected() {
            return this.value === this.value_1 ? 1 : this.value === this.value_2 ? 2 : 3
        }
    },
    methods: {
        getWidth() {
            const option = this.$refs[`option${this.selected}`];
            if (!option) this.width = "0px";
            this.width = option.offsetWidth + 'px'
        },
        getLeft() {
            const option = this.$refs[`option${this.selected}`];
            if (!option) this.left = "0px";
            this.left = option.offsetLeft + 'px'
        },
        resize() {
            this.getWidth();
            this.getLeft();
        }
    },
    watch: {
        model: {
            handler: function(e) {
                this.value = e;
                setTimeout(() => this.resize(), 500);
            },
            immediate: true
        },
        value(e) {
            this.resize();
            this.$emit('change', e)
        }
    },
    mounted() {
        this.getWidth()
    }
}
</script>

<style lang="scss">

.form-switch {
    position: relative;
    border: solid 6px var(--color-bg-grey);
    background-color: var(--color-bg-grey);
    display: flex !important;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;
    user-select: none;

    button {
        height: 100%;
        position: relative;
        z-index: 1;
        cursor: pointer;
        transition: color ease .2s;
        color: var(--color-text-grey);
        padding: 0 10px;
        flex-grow: 1;
        //text-transform: uppercase;

        @media screen and (min-width: 1025px) {
            &:hover {
                color: #000 !important;
            }
        }
    }

    &__bg {
        position: absolute;
        z-index: 0;
        background-color: #fff;
        height: 100%;
        border-radius: 25px;
        left: 0;
        top: 0;
        transition: left ease-in-out .2s, width ease-in-out .2s;
        pointer-events: none;
    }

    &[data-selected="1"] button:nth-of-type(1),
    &[data-selected="3"] button:nth-of-type(3),
    &[data-selected="2"] button:nth-of-type(2) {
        color: #000;
    }
}
</style>