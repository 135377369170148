<template>
    <section class="grid">
        <div class="grid__container">
            <div class="grid__content">
                <slot/>
            </div>
            <div class="grid__aside" v-if="$slots.aside">
                <slot name="aside"/>
            </div>
        </div>
        <div class="grid__bottom" v-if="$slots.bottom">
            <slot name="bottom"/>
        </div>
    </section>
</template>

<script>
export default {
    name: 'GridContainer'
}
</script>

<style lang="scss">
.grid {
    max-width: 1400px;
    margin-bottom: 20px;

    &__container {
        display: flex;
        gap: 20px;
        align-items: flex-start;

        @media screen and (max-width: 1024px) {
            flex-direction: column;

            .grid__aside {
                width: 100%;
            }
        }
    }

    &__content {
        flex-grow: 1;
        width: 100%;
        flex-direction: column;
        z-index: 1;

        & > .grid-content {
            width: 100%;
        }
    }

    &__aside {
        min-width: 280px;
        flex-shrink: 0;
        width: 30%;
        z-index: 0;
        flex-direction: column;

        .table-cell .tag {
            @media screen and (min-width: 1024px) {
                max-width: 180px;
            }
        }
        .grid-card .table-row td:first-of-type {
            max-width: 40%;
        }
    }

    &__content,
    &__aside {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;

        @media screen and (min-width: 1025px) {
            position: sticky;
            top: 20px;
            //overflow: hidden;
        }

        & > * {
            flex-grow: 1;
        }
    }

    &__bottom {
        margin-top: 20px;
        --filter-height: 0px;
    }

    .table-row td {
        word-break: break-all;

        &:not(:first-of-type) {
            white-space: pre-line;
        }
    }

    .section-table-infos {
        margin: 0;

        table {
            min-width: 100%;
            max-width: 100%;
        }
    }

    .grid-card {
        margin-bottom: 0;
    }
}

</style>