<template>
    <TemplateDetails :get-function="getRecomOrder" :data="order" :loading="orderLoading">

        <PageBack/>

        <PageHeader :title="$t('order.recom_title')">
            <template v-slot:text v-if="order">
                <TextSimple color="grey">{{ order.brand?.name }} - {{ order.createdAt | dateHour }} - {{ order.customer?.email }}</TextSimple>
                <TagType :value="order.stage"/>
            </template>
            <template v-slot:actions>
                <ButtonLink v-if="order.invoice" :to="order.invoice" icon="download" size="m" color="grey">{{ $t('order.download_invoice') }}</ButtonLink>
            </template>
        </PageHeader>

        <div v-if="order">

            <GridContainer class="order-cms-detail">
                <GridCard>
                    <GridContent>
                        <GridHeader :title="$t('order.general')"/>
                        <SectionTableInfos :fields="fieldsGeneral" :object="order"/>
                    </GridContent>
                </GridCard>

                <GridCard>
                    <GridContent>
                        <GridHeader :title="$t('order.resume')"/>
                        <RecomOrderCardItem v-for="(item, i) in order.items" :key="i" :item="item" :to="{name: 'recom_tradein-n-sell-article', params: {id: item.tradeIn.id}, query: { view: 'sell' } }" :return-item="returnObject?.orderReturnItems?.find(x => x?.orderItem?.id === item.id)"/>
                        <br>
                        <br>
                        <SectionTableInfos :fields="fieldsPaiement" :object="order"/>
                    </GridContent>
                </GridCard>

                <template v-slot:aside>

                    <GridCard>
                        <GridContent>
                            <GridHeader :title="$t('order.shipping_title')"/>
                            <SectionTableInfos :fields="fieldsShipping" :object="order"/>
                        </GridContent>
                    </GridCard>

                    <GridCard v-if="returnObject">
                        <GridContent>
                            <GridHeader :title="$t('order.return_title')"/>
                            <SectionTableInfos :fields="fieldsReturn" :object="returnObject"/>
                        </GridContent>
                    </GridCard>

                    <GridCard>
                        <GridContent>
                            <Collapse
                                    v-if="order?.activities?.length"
                                    :open="true"
                                    :title="$t('order.track_order')"
                            >
                                <GridCalendar>
                                    <GridCalendarDate
                                            v-for="(activity, i) in order.activities"
                                            :key="i"
                                            :title="$t(`global.${ activity.event}`)"
                                            :date="activity.updatedAt"
                                            :error="activity.event === 'exception'"
                                            :comment="activity.metadata?.message"
                                    />
                                </GridCalendar>
                            </Collapse>
                            <Collapse
                                    v-if="order?.shipment?.activities?.length"
                                    :title="$t('order.track_shipping')"
                            >
                                <GridCalendar>
                                    <GridCalendarDate
                                            v-for="(activity, i) in order.shipment.activities"
                                            :key="i"
                                            :title="$t(`global.${ activity.event}`)"
                                            :date="activity.updatedAt"
                                            :error="activity.event === 'exception'"
                                            :comment="activity.metadata?.message"
                                    />
                                </GridCalendar>
                            </Collapse>
                            <Collapse
                                    v-if="order?.orderReturns?.[0]?.shipment?.activities?.length"
                                    :title="$t('order.track_return')"
                            >
                                <GridCalendar>
                                    <GridCalendarDate
                                            v-for="(activity, i) in order.orderReturns?.[0].shipment.activities"
                                            :key="i"
                                            :title="$t(`global.${ activity.event}`)"
                                            :date="activity.updatedAt"
                                            :error="activity.event === 'exception'"
                                            :comment="activity.metadata?.message"
                                    />
                                </GridCalendar>
                            </Collapse>
                        </GridContent>
                    </GridCard>

                </template>

            </GridContainer>

        </div>

    </TemplateDetails>
</template>

<script>
import SectionTableInfos from '@/components/sections/SectionTableInfos';
import TemplateDetails from '@/components/templates/TemplateDetails';
import ButtonLink from '@/components/ui/button/ButtonLink';
import GridCalendar from '@/components/ui/grid/GridCalendar';
import GridCalendarDate from '@/components/ui/grid/GridCalendarDate';
import GridCard from '@/components/ui/grid/GridCard';
import GridContainer from '@/components/ui/grid/GridContainer';
import GridContent from '@/components/ui/grid/GridContent';
import GridHeader from '@/components/ui/grid/GridHeader';
import RecomOrderCardItem from '@/components/ui/order/RecomOrderCardItem';
import PageBack from '@/components/ui/page/PageBack';
import PageHeader from '@/components/ui/page/PageHeader';
import TagType from '@/components/ui/tag/TagType';
import TextSimple from '@/components/ui/text/TextSimple';
import { mapActions, mapState } from 'vuex';
import Collapse from '@/components/ui/collapse/Collapse';

export default {
    name: "OrderPage",
    components: {
        Collapse,
        ButtonLink,
        GridCalendarDate,
        RecomOrderCardItem, TextSimple, TagType, GridCalendar, GridHeader, GridContent, GridCard, GridContainer, SectionTableInfos, PageHeader, PageBack, TemplateDetails},
    data() {
        return {
            fieldsGeneral: [
                {key: 'brand.name', type: 'client', translationKey: 'client'},
                {key: 'reference', translationKey: 'id'},
                {key: 'createdAt', translationKey: 'orderCreateAt', type: 'date'},
                {key: 'customer.id', type: 'CmsCustomer', translationKey: "user"},
                {key: 'channel', type: 'tagType', translationKey: 'canal'},
                {key: 'itemsNb', translationKey:"countArticles"},
                {key: 'paymentState', type: 'tagTypePayments', translationKey: 'payments'},
                {key: 'payments', type: 'cmsOrderTransactionId', translationKey: "transactionId"},
                {key: 'state', translationKey:"orderStatus", type: 'tagType'},
            ],
            fieldsShipping: [
                {key: 'shipment.state', translationKey: "shipmentStatus", type: 'tagType', size: 'm'},
                {key: 'orderReturns', type: 'tagTypeReturns', size: 'm'},
                {key: 'shipment.trackingNumber', translationKey: 'trackingId', type: 'cmsOrderShippingTracking'},
                {key: 'shippingMethod.name', translationKey: 'carrierService'},
                {key: 'shippingAddress', type: 'address'},
                {key: 'billingAddress', type: 'address'},
            ],
            fieldsPaiement: [
                {key: 'itemsTotal', translationKey:"subtotal", type: 'formatPrice', force: true},
                {key: 'shippingTotal', type: 'formatPrice', force: true},
                {key: 'taxTotal', type: 'formatPrice', force: true},
                {key: 'discountTotal', type: 'formatPrice', force: true},
                {key: 'total', translationKey: 'totalttc', type: 'formatPrice', force: true}
            ],
            fieldsReturn: [
                {key: 'state', type: 'tagType'},
                {key: 'shipment.trackingNumber', type: 'cmsOrderShippingTracking'},
                {key: 'orderReturnItems.length', translationKey: 'countArticles'},
                {key: 'shipment.trackingNumber', type: 'cmsOrderShippingTracking'},
                {key: 'shippingVoucher', type: 'pdf'},
            ]
        }
    },
    computed: {
        ...mapState(['order', 'orderLoading', 'clients']),
        shopifyOrderUrl() {
            if (!this.order || !this.order.client) return false;
            return `${this.clients.find(c => c.id === this.order.client.id)?.frontUrl}/admin/new-orders/${this.order.shopifyId}`
        },
        returnObject() {
            return this.order?.orderReturns?.[0]
        }
    },
    methods: {
        ...mapActions(['getRecomOrder'])
    }
}
</script>

<style lang="scss">
.order-cms-detail {
    .grid-calendar ul {
        padding: 0;
        gap: 15px;
    }

    .grid-calendar-date > span {
        border-color: #fff;
    }

    .grid-calendar-date {
        align-items: flex-start;

        & > div {
            gap: 4px;
        }
    }

    .grid-calendar-date.is-error {
        p + p {
            text-transform: lowercase;
        }
    }
}
</style>
