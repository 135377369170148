<template>
    <div class="grid-history">
        <ul>
            <li v-for="(field, i) in visibleDates" :key="i">
                <Tag
                    :text="field.label"
                    :color="colorMapping[field.source].color || 'black'"
                    :icon-left="colorMapping[field.source].icon"
                    class="grid-history__tag"
                />
                <span v-if="field.operator">{{ $t("global.by")}} {{ field.operator }} </span>
                <div class="grid-history__date" v-if="field.date">
                    <div class="grid-history__comment" v-if="field.comment">
                        <div v-html="field.comment"></div>
                    </div>
                    {{ field.date | momentDateTime}}
                </div>
            </li>
        </ul>
        <div 
            v-if="dates.length > 5" 
            @click="toggleExtend"
            class="grid-history__expand"
        >
            <div class="grid-history__expandInner">
                {{ extend ? $t('global.see_less') : $t('global.see_more') }}
                <SvgIcon v-if="!extend" name="plus">{{ $t('global.see_more') }}</SvgIcon>
                <SvgIcon v-else name="minus">{{ $t('global.see_less') }}</SvgIcon>
            </div>
        </div>
    </div>
</template>

<script>
import Tag from '@/components/ui/tag/Tag';
import SvgIcon from '@/components/ui/svg/SvgIcon';

export default {
    name: 'GridHistory',
    props: {
        dates: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        Tag,
        SvgIcon
    },
    data() {
        return {
            extend: false,
            colorMapping: {
                "user": { 
                    color: "green",
                    icon: "bag",
                },
                "carrier": { 
                    color: "pink",
                    icon: "shipping",
                },
                "core": { 
                    color: "blue",
                    icon: "user",
                },
                "warehouse": { 
                    color: "orange",
                    icon: "eye",
                },
            }
        }
    },
    computed: {
        visibleDates() {
            if (this.extend) {
                return this.dates;
            }
            return this.dates.slice(0, 5);
        }
    },
    methods: {
        toggleExtend() {
            this.extend = !this.extend;
        }
    }
}
</script>

<style lang="scss">
.grid-history {
    position: relative;

    ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &__tag {
        display: inline-flex;
        white-space: nowrap;
        margin-right: 10px;
        .icon{
            transform: scale(1.3);
        }
    }

    &__comment {
        font-style: italic;
        a {
            color: blue;
            text-decoration: underline;
            display: inline;
        }
    }

    &__date {
        position: relative;
        padding: 2px 0 20px 14px;
        margin-left: 5px;
        margin-top: 10px;
        color: var(--color-text-grey);

        &:before {
            content: '';
            display: block;
            width: 1px;
            height: 100%;
            z-index: 0;
            position: absolute;
            top: 0;
            left: 0;
            border-right: var(--border-2);
        }
    }

    &__expand {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }
    &__expandInner {
        display: inline-flex;
        padding-inline: 4px;
        padding-bottom: 3px;
        gap: 5px;
        border-bottom: var(--border-2);
    }
}
</style>