<template>
    <div class="images-lightbox">
        <div class="images-lightbox__list">
            <div v-for="(img, i) in images" :key="i" @click="openImage(i)">
                <img :src="img" :alt="i">
                <SvgIcon name="search"/>
            </div>
        </div>
        <Popin ref="popin">
            <template v-slot:content>
                <div class="lightbox-navigation">
                    <button 
                        class="nav-button prev" 
                        @click="previousImage"
                        v-if="withNavigation"
                        v-show="currentIndex > 0"
                    >
                        <SvgIcon name="left"/>
                    </button>

                    <img :src="image" alt="image" class="lightbox-image">

                    <button 
                        class="nav-button next" 
                        @click="nextImage"
                        v-if="withNavigation"
                        v-show="currentIndex < images.length - 1"
                    >
                        <SvgIcon name="right"/>
                    </button>
                </div>
            </template>
        </Popin>
    </div>
</template>

<script>
import SvgIcon from '@/components/ui/svg/SvgIcon';
import Popin from '@/components/ui/popin/Popin';
export default {
    name: 'ImagesWithLightbox',
    components: {Popin, SvgIcon},
    props: {
        images: Array,
        withNavigation: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            image: this.images[0],
            currentIndex: 0
        }
    },
    methods: {
        openImage(index) {
            this.currentIndex = index;
            this.image = this.images[index];
            this.$refs.popin.open();
        },
        previousImage() {
            if (this.currentIndex > 0) {
                this.currentIndex--;
                this.image = this.images[this.currentIndex];
            }
        },
        nextImage() {
            if (this.currentIndex < this.images.length - 1) {
                this.currentIndex++;
                this.image = this.images[this.currentIndex];
            }
        }
    }
}
</script>

<style lang="scss">
.images-lightbox {

    img {
        width: 100%;
        height: auto;
    }

    &__list {
        display: flex;
        gap: 10px;

        img {
            max-width: 150px;
        }

        div {
            position: relative;
            overflow: hidden;
            border-radius: var(--border-radius-1);

            &:not(:hover) .icon {
                opacity: 0;
                transform: scale(1.2);
            }
        }

        .icon {
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: opacity ease-in-out .4s, transform ease-in-out .4s;
            background-color: rgba(0,0,0, .3);
            --svg-color: #fff;
            cursor: pointer;

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
    
    .lightbox-navigation {
        display: flex;
        width: 100%;
        min-height: 100px;
        --svg-color: white;

        .nav-button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            flex-shrink: 0;
            background: rgba(0, 0, 0, 0.5);
            border: none;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: background-color 0.3s;
            --svg-color: #fff;

            &:hover {
                background: rgba(0, 0, 0, 0.7);
            }

            svg {
                width: 24px;
                height: 24px;
            }

            &.prev {
                left: 30px;
            }

            &.next {
                right: 30px;
            }
        }

        img {
            height: auto;
            display: block;
        }
    }
}
</style>