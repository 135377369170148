<template>
    <div>
        <div class="product-card">
            <div class="product-card__top">
                <div class="product-card__images">
                    <img v-if="product.photo1 && !displayPlaceholder" :src="product.photo1" @error="displayPlaceholder = true">
                    <img class="product-card__placeholder" v-else src="/img/placeholder/placeholder-tshirt.png">
                    <img v-if="product.photo2" :src="product.photo2">
                </div>
                <ProductButtonLightbox class="product-card__eye" :product="product"/>
            </div>
            <div class="product-card__bottom">
                <TextTitle size="xs" tag="h2">{{ product.title }} - {{ product.price | money }}</TextTitle>
                <TextSimple v-if="product.size">{{ $t('global.size') }} {{ product.size }}</TextSimple>
                <TagType class="product-card__condition" :value="product.condition || product.etat"/>
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>
import TextTitle from '@/components/ui/text/TextTitle';
import TextSimple from '@/components/ui/text/TextSimple';
import TagType from '@/components/ui/tag/TagType';
import ProductButtonLightbox from '@/components/ui/product/ProductButtonLightbox';
export default {
    name: 'ProductCard',
    components: {ProductButtonLightbox, TextSimple, TextTitle, TagType},
    data() {
        return {
            displayPlaceholder: false
        }
    },
    props: {
        product: Object
    }
}
</script>

<style lang="scss">
.product-card {
    background-color: #fff;
    padding: 10px 10px 15px;
    border-radius: var(--border-radius-2);
    position: relative;

    .slider-container {
        height: auto
    }

    &__top {
        position: relative;
    }

    &__eye > button {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
    }

    &__images {
        position: relative;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
        }

        img:nth-of-type(2) {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
            opacity: 0;
            pointer-events: none;
            object-fit: cover;
        }

        &:hover img:nth-of-type(2) {
            opacity: 1;
        }
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding-top: 5px;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &__condition {
        margin-top: 5px;
    }

    .input-checkbox {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;
    }

    &__placeholder {
        aspect-ratio: 1;
        object-fit: cover;

        & ~ img {
            display: none;
        }
    }
}
</style>