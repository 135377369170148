<template>
  <TemplateTable
    :title="$t('menu.recom_tradein-articles')"
    :text="$t('recom_tradein-articles.description')"
    name="recomTradeinArticles"
    :url="$listRoutes.recomTradeinArticles"
    :table="recomTradeinArticles"
    :filters="recomTradeinArticlesFilters"
    :fields="fields"
  />
</template>

<script>
import TemplateTable from '@/components/templates/TemplateTable';
import { mapState } from 'vuex';
import currencyRouteMixin from "@/mixins/currencyRouteMixin";

export default {
    name: 'RecomTradeinArticles',
    mixins: [currencyRouteMixin],
    components: {TemplateTable},
    data() {
        return {
            fields: [
                { key: 'id', type: 'eye', route: 'recom_tradein-n-sell-article', size: "xs"},
                { key: 'brand.name', type: 'client', translationKey: 'client', size: 'm' },
                { key: 'tradeInCart.customer.email', type: 'CmsCustomer', size: 'xl', translationKey: "email" },
                { key: 'reference', size: 'm', type: "copy", translationKey: "article_id"},
                { key: 'ean', size: 'm', type: "copy" },
                { key: 'state', type: 'tagType', size: 'm' },
                { key: 'createdAt', size: 'l', type: 'date', translationKey: 'tradeinCreateAt'},
                { key: 'controlReview.createdAt', size: 'l', type: 'date', translationKey:'controlReviewCreatedAt'},
                { key: 'creditedAt', type: 'date', translationKey: 'dateCredit', size: 'm'},
                { key: 'initialReview.condition', type: 'tagType', translationKey: 'declaredCondition'},
                { key: 'controlReview.condition', type: 'tagType', size: 'm' },
                { key: 'initialReview.price', type: 'formatPrice', translationKey: 'purposed_price', size: 'm' },
                { key: 'priceCredit', type: 'formatPrice', translationKey: 'credit_price', size: 'm' },
                { key: 'priceResale', type: 'formatPrice', size: 'm' },
                { key: 'tradeInCart.channel', translationKey: 'articleSource', type: 'tagType', size: 'm' },
                { key: 'tradeInCart.customer.profile', type: 'tagType', size: 'm' },
                { key: 'tradeInCart.customer.countryCode'}
            ]
        }
    },
    computed: {
        ...mapState(['recomTradeinArticles', 'recomTradeinArticlesFilters'])
    }
}
</script>
